import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

import abingtonLaneLogo from '../../projects/AbingtonLane/abington-lane-logo.png'
import abodeLogo from '../../projects/Abode/abode-logo.jpg'
import friendsOfThaiDaughtersLogo from '../../projects/FriendsOfThaiDaughters/ftd-logo.png'
import jennaHunterLogo from '../../projects/JennaHunter/jenna-hunter-logo.png'
import rtdLogo from '../../projects/Rtd/rtd-logo.svg'

const ProjectsPage = () => {
  const imageWrapperBaseCss = `
    padding: 1.45rem 1.1rem;
    text-align: center;
    @media (max-width: 700px) {
      padding: .25rem 1.1rem;
    }
  `
  const imageWrapperCss = `
    ${imageWrapperBaseCss}
    width: 33%;
    @media (max-width: 700px) {
      width: 50%;
    }
  `
  const wideImageWrapperCss = `
    ${imageWrapperBaseCss}
    width: 50%;
    @media (max-width: 700px) {
      width: 100%;
    }
  `

  return (
    <Layout>
      <SEO title="Projects" />
      <h2
        css={`
          text-align: center;
          margin-bottom: 2rem;
        `}
      >
        Some of our past and current clients include these great companies
      </h2>
      <section
        css={`
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: 'center';
        `}
      >
        <div css={imageWrapperCss}>
          <img
            src={rtdLogo}
            alt="RTD - Region Transportation District"
            css={{}}
          />
        </div>
        <div css={imageWrapperCss}>
          <img src={abingtonLaneLogo} alt="Abington Lane" css={{}} />
        </div>
        <div css={imageWrapperCss}>
          <img src={abodeLogo} alt="Abode" css={{}} />
        </div>
        <div css={wideImageWrapperCss}>
          <img
            src={friendsOfThaiDaughtersLogo}
            alt="Friends of Thai Daughters"
            css={{}}
          />
        </div>
        <div css={wideImageWrapperCss}>
          <img src={jennaHunterLogo} alt="Jenna Hunter" css={{}} />
        </div>
      </section>
    </Layout>
  )
}

export default ProjectsPage
